html,
body,
main,
div,
h1,h2,h3 {
  padding: 0;
  border: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Osaka,Hiragino Sans,sans-serif;
  font-size: 16px;
  font-weight: normal;
}

div#root {
  background-color: #dddddd;
}