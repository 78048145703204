:root {
  /* main */
  --main-border: #aaaaaa;

  /* title */
  --title-height: 30px;

  --title-background: #ffffff;
  --title-text: #000000;


  /* display */
  --display-background: #222222;
  --display-text: #ffffff;
  --display-table: #cccccc;

  --display-hi: #ff6622;
  --display-mizu: #66eeff;
  --display-kaminari: #ffcc44;
  --display-koori: #44aaff;
  --display-ryu: #aaff99;

  --display-doku: #dd99ee;
  --display-mahi: #ffff55;
  --display-suimin: #aaddff;
  --display-bakuha: #ff8833;
  --display-genki: #4466ff;

  --display-kizetsu: #ffff55;

  --display-otoshiwana: #99ee99;
  --display-shibirewana: #F7F670;
  --display-senkou: #EECC55;
  --display-oto: #bbbbbb;
  --display-koyashi: #ddcc33;
  --display-niku: #ff4444;


  /* control */
  --control-height: 160px;

  --control-background: #ffffff;
  --control-text: #000000;

  --control-ui-light: #aaaaaa;
  --control-ui-light2: #cccccc;

  --control-fill: #66666666;

  --control-link: #2233dd;


  /* other */
  --actionpterygii: #33cccc;

  --vh: 100svh;

}

#main {
  max-width: 800px;
  height: var(--vh);
  margin: 0 auto;
  background-color: var(--title-background);
}

#title {
  height: var(--title-height);
  background-color: var(--title-background);
  color: var(--title-text);
  font-size: 20px;
  padding-left: 5px;
}

/* Display */
#display {
  height: calc(100% - (var(--title-height) + var(--control-height)));
  background-color: var(--display-background);
  color: var(--display-text);
  font-size: 16px;
  overflow-y:scroll;
  overflow-x: hidden;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  table-layout: 10px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 5px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#display::-webkit-scrollbar{
  display: none;
}

.category {
  margin-top: 20px;
  margin-bottom: 20px;
}

.category_name {
  font-size: 20px;
}

.monsters {
  padding-top: 10px;
  padding-bottom: 10px;
}

.monster {
  padding-top: 5px;
  padding-bottom: 5px;
}

.monster_head {
  display: flex;
}

.monster_alias {
  margin-right: 15px;
}

.monster_name {
}

.monster_body {
  display: flex;
  flex-wrap: wrap;
}

.valid {
  border: solid 1px var(--display-table);
}
.valid:not(:last-child) {
  border-right: none;
}

.valid div {
  width: 20px;
  text-align: center;
}

.valid_head {
  font-family: 'Courier New', Courier, monospace;
  word-wrap: break-word;
  line-height: 16px;
  font-size: 16px;
  padding-top: 4px;
  height: 37px;
  border-bottom: solid 1px var(--display-table);
}
.valid:nth-child(1) .valid_head {
  color: var(--display-hi);
}
.valid:nth-child(2) .valid_head {
  color: var(--display-mizu);
}
.valid:nth-child(3) .valid_head {
  color: var(--display-kaminari);
}
.valid:nth-child(4) .valid_head {
  color: var(--display-koori);
}
.valid:nth-child(5) .valid_head {
  color: var(--display-ryu);
}
.valid:nth-child(6) .valid_head {
  color: var(--display-doku);
}
.valid:nth-child(7) .valid_head {
  color: var(--display-mahi);
}
.valid:nth-child(8) .valid_head {
  color: var(--display-suimin);
}
.valid:nth-child(9) .valid_head {
  color: var(--display-bakuha);
}
.valid:nth-child(10) .valid_head {
  color: var(--display-genki);
}
.valid:nth-child(11) .valid_head {
  color: var(--display-kizetsu);
}
.valid:nth-child(12) .valid_head {
  color: var(--display-senkou);
}
.valid:nth-child(13) .valid_head {
  color: var(--display-oto);
}
.valid:nth-child(14) .valid_head {
  color: var(--display-shibirewana);
}
.valid:nth-child(15) .valid_head {
  color: var(--display-otoshiwana);
}

.valid_body {
  font-family: monospace;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}


#notes {
  padding: 20px;
}

/* Control */
#control {
  height: var(--control-height);
  background-color: var(--control-background);
  color: var(--control-text);
  font-size: 16px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
}

.spotlight_fill {
  position:fixed;
  top:0;
  left:0;
  right: 0;
  max-width: 800px;
  width:100%;
  height:100%;
  margin: 0 auto;
  background-color: var(--control-fill);
  visibility: visible;
  opacity: 1;
  transition: 0.4s;
}
/* モーダル */
.spotlight_fill__off {
  visibility: hidden;
  opacity: 0;
}

#control_panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
}

.control_select_wrap {
  width: 50%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

#control_panel select {
  width: 100%;
  height: 30px;
}

#menu_button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
}

#menu_icon {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  justify-content: space-around;
  height: 25px;
}

.menu_icon_bar {
  height: 3px;
  width: 31px;
  background-color: var(--control-ui-light);
}

#menu_text {
  width: 31px;
  font-size: 10px;
  font-weight: bold;
  color: var(--control-ui-light);
}

#menu {
  position: absolute;
  right: 20px;
  bottom: 20px;
  border: solid 1px var(--control-ui-light2);
  background-color: var(--control-background);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
}

#menu_links {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-bottom: 10px;
}

.menu_link {
  color: var(--control-link);
  font-size: 20px;
}

.actionpterygii {
  color: var(--actionpterygii);
}

#menu_close {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 0 0 auto;
  cursor: pointer;
}
   
#menu_close::before,
#menu_close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 20px;
  background: var(--control-ui-light);
}
  
#menu_close::before {
  transform: translate(-50%,-50%) rotate(45deg);
}
  
#menu_close::after {
  transform: translate(-50%,-50%) rotate(-45deg);
}
